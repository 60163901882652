const actions = {
  SET_STATE: 'MO/SET_STATE',
  GET_ENQUIRIES: 'MO/GET_ENQUIRIES',
  ENQUIRIES_LOADING: 'MO/ENQUIRIES_LOADING',
  GET_MO_USERS: 'MO/GET_MO_USERS',
  MO_USERS_LOADING: 'MO/MO_USERS_LOADING',
  APPLY_DATE_RANGE_USER: 'MO/APPLY_DATE_RANGE_USER',
  APPLY_DATE_RANGE_ENQUIRY: 'MO/APPLY_DATE_RANGE_ENQUIRY',
}

export default actions
