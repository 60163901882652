const actions = {
  SET_STATE: 'users/SET_STATE',
  GET_USERS: 'users/GET_USERS',
  APPLY_DATE_RANGE: 'users/APPLY_DATE_RANGE',
  ADD_USER: 'users/ADD_USER',
  BATCH_ADD_USER: 'users/BATCH_ADD_USER',
  ADD_USER_LOADLESS: 'users/ADD_USER_LOADLESS',
  UPDATE_USER: 'users/UPDATE_USER',
  DELETE_USER: 'users/DELETE_USER',
  BATCH_DELETE_USER: 'users/BATCH_DELETE_USER',
  TOGGLE_LOADING: 'users/TOGGLE_LOADING',
}

export default actions
