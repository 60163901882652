import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import users from './users/reducers'

import MO from './MO/reducers'
import enquiries from './enquiries/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    users,
    MO,
    enquiries,
  })
