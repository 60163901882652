// import store from 'store'
// import qs from 'qs'
// eslint-disable-next-line no-unused-vars
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { store as reduxStore } from 'index'
import axios from 'axios'
import { message } from 'antd'
import moment from 'moment'
import actions from './actions'

const getEnquiries = async () => {
  try {
    const properties = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=properties-backup`,
    )

    const users = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=mo-users`,
    )
    // Unique enquirers (users who have enquired on a minimum of 1 property)
    let UniqueEnquirers = 0
    users.data.body.forEach((value, index) => {
      if (value.InterestedProperties && value.InterestedProperties.length !== 0) {
        UniqueEnquirers = UniqueEnquirers + 1
      }
    })
    if (users.data.status === 200) {
      message.success('Enquiries loaded successfully.')
      let EnquiriesData = []
      // Mapping PIDs to property details
      const PID_TO_DETAILS_MAP = new Map()
      properties.data.body.forEach(property => {
        PID_TO_DETAILS_MAP.set(property.PID, property)
      })

      const team = await axios.get(
        `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=propreturns-team`,
      )

      const teamNumbers = team.data.body.map(employee => {
        return employee.Phone //mail
      })

      // Pushing enquiries

      users.data.body.forEach(user => {
        // Removing enquiries by the team
        if (!teamNumbers.includes(user.Phone))
          user.InterestedProperties?.forEach(InterestedProperty => {
            EnquiriesData.push({
              PID: InterestedProperty.PID,
              City: InterestedProperty.PID.startsWith('FPID') // Mumbai for fractional
                ? 'Mumbai'
                : InterestedProperty.PID.startsWith('CFID') // Pune for construction finance
                ? 'Pune'
                : !PID_TO_DETAILS_MAP.has(InterestedProperty.PID) // Check if property data is available (lost some due to deletion and backup not configured properly)
                ? 'Property data not available'
                : PID_TO_DETAILS_MAP.get(InterestedProperty.PID).PropertyDetails.City,
              RM: user?.RM || null,
              Name: user.Name,
              Phone: user.Phone,
              WebsiteSource: InterestedProperty.Source,
              createdAt: InterestedProperty.CreatedAt,
              UTM_Source: InterestedProperty.UTM_Data?.UTM_Source || null,
              UTM_Medium: InterestedProperty.UTM_Data?.UTM_Medium || null,
              UTM_Campaign: InterestedProperty.UTM_Data?.UTM_Campaign || null,
            })
          })
      })
      let Users = users.data.body
      console.log({ EnquiriesData, UniqueEnquirers, Users })
      return { EnquiriesData, UniqueEnquirers, Users }
    }
    if (users.data.status === 500) {
      message.error('There was an error processing your request. Try again or call Somil.')
      console.log(users.data.body)
      return null
    }
    return null
  } catch (err) {
    message.error('There was an error processing your request. Try again or call Somil.')
    console.log(err)
    return null
  }
}
const applyDateRangeUser = async DateRange => {
  try {
    const RangedUsers = []
    const users = reduxStore.getState().MO.OrignalMoUser
    if (!DateRange) return users
    users.forEach((value, index) => {
      if (
        dateFromObjectId(value._id) > DateRange[0] &&
        dateFromObjectId(value._id) < DateRange[1]
      ) {
        RangedUsers.push(value)
      }
    })
    message.success('Date range applied.')
    return RangedUsers
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.UsersData, tableLoading: false }
    console.log(err)
    return temp
  }
}
const applyDateRangeEnquiry = async DateRange => {
  try {
    const RangedEnquiries = []
    const enquiries = reduxStore.getState().MO.OriginalEnquiries
    const uniqueEnquirers = reduxStore.getState().MO.OriginalUniqueEnquirers
    if (!DateRange) return { RangedEnquiries: enquiries, UniqueEnquirers: uniqueEnquirers }
    enquiries.forEach((value, index) => {
      if (
        moment(value.createdAt) > moment(DateRange[0]).startOf('day') &&
        moment(value.createdAt) < moment(DateRange[1]).startOf('day')
      ) {
        RangedEnquiries.push(value)
      }
    })
    let UniqueEnquirers = []
    message.success('Date range applied.')
    return { RangedEnquiries, UniqueEnquirers }
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().enquiries.OriginalEnquiries, EnquiriesLoading: false }
    console.log(err)
    return temp
  }
}
const dateFromObjectId = function(objectId) {
  return moment(new Date(parseInt(objectId.substring(0, 8), 16) * 1000))
}
const getMoUsers = async () => {
  try {
    const users = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=mo-users`,
    )
    const team = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=propreturns-team`,
    )

    const teamNumbers = team.data.body.map(employee => {
      return employee.Phone //mail
    })
    const noTeamUser = []
    users.data.body.forEach(user => {
      if (!teamNumbers.includes(user.Phone)) {
        noTeamUser.push(user)
      }
    })
    if (users.data.status === 200) {
      message.success('MO Users loaded successfully.')
      return noTeamUser
    } else {
      message.error('There was an error processing your request. Please try again after some time')
    }
    return null
  } catch (err) {
    message.error('There was an error processing your request. Please try again after some time')
    console.log(err)
    return null
  }
}

function* APPLY_DATE_RANGE_USER({ payload: { DateRange } }) {
  yield put({
    type: 'MO/MO_USERS_LOADING',
    payload: {
      UsersLoading: true,
    },
  })
  const users = yield call(applyDateRangeUser, DateRange)
  yield put({ type: 'MO/SET_STATE', payload: { MoUser: users } })
  yield put({
    type: 'MO/MO_USERS_LOADING',
    payload: {
      UsersLoading: false,
    },
  })
}
function* APPLY_DATE_RANGE_ENQUIRY({ payload: { DateRange } }) {
  yield put({
    type: 'MO/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: true,
    },
  })
  const { RangedEnquiries, UniqueEnquirers } = yield call(applyDateRangeEnquiry, DateRange)
  yield put({ type: 'MO/SET_STATE', payload: { Enquiries: RangedEnquiries } })
  yield put({
    type: 'MO/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: false,
    },
  })
}

function* GET_ENQUIRIES() {
  yield put({
    type: 'MO/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: true,
    },
  })
  const enquiries = yield call(getEnquiries)
  yield put({
    type: 'MO/SET_STATE',
    payload: {
      Enquiries: enquiries.EnquiriesData,
      OriginalEnquiries: enquiries.EnquiriesData,
      UniqueEnquirers: enquiries.UniqueEnquirers,
      OriginalUniqueEnquirers: enquiries.UniqueEnquirers,
      Users: enquiries.Users,
    },
  })
  yield put({
    type: 'MO/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: false,
    },
  })
}

function* GET_MO_USERS() {
  yield put({
    type: 'MO/MO_USERS_LOADING',
    payload: {
      UsersLoading: true,
    },
  })
  const response = yield call(getMoUsers)
  console.log(response)
  yield put({
    type: 'MO/SET_STATE',
    payload: { MoUser: response, OrignalMoUser: response },
  })
  yield put({
    type: 'MO/MO_USERS_LOADING',
    payload: {
      MoUserLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ENQUIRIES, GET_ENQUIRIES),
    takeEvery(actions.GET_MO_USERS, GET_MO_USERS),
    takeEvery(actions.APPLY_DATE_RANGE_USER, APPLY_DATE_RANGE_USER),
    takeEvery(actions.APPLY_DATE_RANGE_ENQUIRY, APPLY_DATE_RANGE_ENQUIRY),
  ])
}
