import actions from './actions'

const initialState = {
  MoUser: null,
  MoUserLoading: true,
  OrignalMoUser: null,
  Enquiries: null,
  EnquiriesLoading: true,
  UniqueEnquirers: null,
  OriginalUniqueEnquirers: null,
  Users: null,
}

export default function leadsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ENQUIRIES_LOADING:
      return { ...state, ...action.payload }
    case actions.GET_MO_USERS:
      return { ...state, ...action.payload }
    case actions.MO_USERS_LOADING:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
