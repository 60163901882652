import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  //MO USER
  {
    path: '/users/mo-users',
    Component: lazy(() => import('pages/MO/MoUser')),
    exact: true,
  },

  {
    path: '/enquiries/MO',
    Component: lazy(() => import('pages/MO/MoEnquiries')),
    exact: true,
  },

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/401',
    Component: lazy(() => import('pages/auth/401')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const getPermissionStatus = (AccessibleRoutes, path) => {
  let routePermission = false
  const routePermissionCheck = AccessibleRoutes
    ? AccessibleRoutes.AccessibleRoutes
    : ['/auth/login', '/auth/404', '/auth/500', '/auth/401']
  if (routePermissionCheck) {
    routePermissionCheck.forEach(item => {
      if (item === path) {
        routePermission = true
      }
    })
  }
  return routePermission
}

const mapStateToProps = ({ settings, properties, user }) => ({
  routerAnimation: settings.routerAnimation,
  AccessibleRoutes: user.AccessibleRoutes,
})
const Router = ({ history, routerAnimation, AccessibleRoutes }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Redirect to="/enquiries/MO" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        AccessibleRoutes={AccessibleRoutes}
                        render={() => {
                          let routePermission = getPermissionStatus(AccessibleRoutes, path)
                          if (routePermission) {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }
                          return <Redirect to="/auth/401" />
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
