import { takeEvery, put, all, call } from 'redux-saga/effects'
// import store from 'store'
// import qs from 'qs'
// eslint-disable-next-line no-unused-vars
import { store as reduxStore } from 'index'
import axios from 'axios'
import { message } from 'antd'
import moment from 'moment'
import actions from './actions'

const getAllUsers = async () => {
  const collection = 'users'
  try {
    const users = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=${collection}`,
    )

    const team = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=propreturns-team`,
    )

    const teamNumbers = team.data.body.map(employee => {
      return employee.Phone
    })
    const UsersAfterRemovingTeam = users.data.body.filter(user => !teamNumbers.includes(user.Phone))
    message.success('Users loaded successfully.')
    return UsersAfterRemovingTeam
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.UsersData, tableLoading: false }
    console.log(err)
    return temp
  }
}

const applyDateRange = async DateRange => {
  try {
    const RangedUsers = []
    const users = reduxStore.getState().users.OriginalUsersData
    if (!DateRange) return users
    users.forEach((value, index) => {
      if (
        moment(value.createdAt).isAfter(DateRange[0]._d.toDateString()) &&
        moment(value.createdAt).isBefore(DateRange[1]._d.toDateString())
      ) {
        RangedUsers.push(value)
      }
    })
    message.success('Date range applied.')
    return RangedUsers
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.UsersData, tableLoading: false }
    console.log(err)
    return temp
  }
}

const UpdateData = async () => {
  try {
    const response = await axios.get('/users', {
      params: {
        email: reduxStore.getState().user.email,
      },
      mode: 'cors',
      timeout: 6000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.userData, tableLoading: false }
    console.log(err)
    return temp
  }
}

const AddData = async users => {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.post('/users/batchpost', {
      data: { users },
      timeout: 6000,
    })

    message.success('users successfully updated.')
    const users = await axios.get('/users', {
      params: {
        email: reduxStore.getState().user.email,
      },
      mode: 'cors',
      timeout: 6000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return users.data
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.userData, tableLoading: false }
    console.log(err)
    return temp
  }
}

const DeleteData = async userIds => {
  try {
    // eslint-disable-next-line no-unused-vars
    const response = await axios.delete('/users/delete', {
      params: {
        email: reduxStore.getState().user.email,
      },
      data: { userIds, email: reduxStore.getState().user.email },
      timeout: 6000,
    })
    message.success('user successfully deleted.')
    const users = await axios.get('/users', {
      params: {
        email: reduxStore.getState().user.email,
      },
      mode: 'cors',
      timeout: 6000,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return users.data
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().users.userData, tableLoading: false }
    console.log(err)
    return temp
  }
}

function* GET_USERS() {
  yield put({
    type: 'users/TOGGLE_LOADING',
    payload: {
      tableLoading: true,
    },
  })
  const users = yield call(getAllUsers)
  yield put({
    type: 'users/SET_STATE',
    payload: { UsersData: users, OriginalUsersData: users },
  })
  yield put({
    type: 'users/TOGGLE_LOADING',
    payload: {
      tableLoading: false,
    },
  })
}

function* APPLY_DATE_RANGE({ payload: { DateRange } }) {
  yield put({
    type: 'users/TABLE_LOADING',
    payload: {
      tableLoading: true,
    },
  })
  const users = yield call(applyDateRange, DateRange)
  yield put({ type: 'users/SET_STATE', payload: { UsersData: users } })
  yield put({
    type: 'users/TABLE_LOADING',
    payload: {
      tableLoading: false,
    },
  })
}

function* ADD_USER({ payload: { newuserData } }) {
  const data = { ...reduxStore.getState().users.userData, tableLoading: true }
  yield put({
    type: 'users/TOGGLE_LOADING',
    payload: {
      userData: data,
    },
  })
  let users = yield call(AddData, newuserData)
  users = { ...users, tableLoading: false }
  yield put({ type: 'users/SET_STATE', payload: { userData: users } })
}

function* DELETE_USER({ payload: { userIds } }) {
  const data = { ...reduxStore.getState().users.userData, tableLoading: true }
  yield put({
    type: 'users/TOGGLE_LOADING',
    payload: {
      userData: data,
    },
  })
  const users = yield call(DeleteData, userIds)
  yield put({ type: 'users/SET_STATE', payload: { userData: users } })
}

function* UPDATE_USER() {
  const users = yield call(UpdateData)
  yield put({ type: 'users/SET_STATE', payload: { userData: users } })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USERS, GET_USERS),
    takeEvery(actions.APPLY_DATE_RANGE, APPLY_DATE_RANGE),
    takeEvery(actions.ADD_USER, ADD_USER),
    takeEvery(actions.UPDATE_USER, UPDATE_USER),
    takeEvery(actions.DELETE_USER, DELETE_USER),
  ])
}
