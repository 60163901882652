const getCategoryCheck = AccessibleRoutesArray => {
  const CategoryTitle = {
    Leads: false,
    Properties: false,
    Enquires: false,
    Scraping: false,
    Product: false,
    Marketing: false,
  }

  AccessibleRoutesArray.forEach(element => {
    if (element.startsWith('/user')) {
      CategoryTitle.Leads = true
    }
    // if (element.startsWith('/property') || element.startsWith('/properties')) {
    //   CategoryTitle.Properties = true
    // }
    if (element.startsWith('/enquiries')) {
      CategoryTitle.Enquires = true
    }
  })
  return CategoryTitle
}

export default async function getMenuData(AccessibleRoutes) {
  const AccessibleRoutesArray = AccessibleRoutes.AccessibleRoutes
  const CategoryTitle = getCategoryCheck(AccessibleRoutesArray)

  return [
    {
      category: CategoryTitle.Leads,
      title: 'Users',
    },
    {
      title: 'Users',
      key: 'MOUsers',
      url: '/users/mo-users',
      icon: 'fe fe-user',
      // roles: ['Admin', 'Developer', 'Manager', 'RM'], // set user roles with access to this route
      // // count: 2,
      // children: [
      //   {
      //     title: 'Users',
      //     key: 'MOUsers',
      //     url: '/users/mo-users',
      //   },
      // ],
    },
    // {
    //   category: CategoryTitle.Properties,
    //   title: 'Properties',
    // },
    // {
    //   title: 'Properties',
    //   key: 'Properties',
    //   icon: 'fe fe-home',
    //   roles: ['Admin', 'Developer', 'Manager', 'RM'], // set user roles with access to this route
    //   // count: 2,
    //   children: [
    //     {
    //       title: 'All Properties',
    //       icon: 'fe fe-home',
    //       key: 'All Properties',
    //       url: '/properties',
    //     },
    //     {
    //       title: 'Order Properties',
    //       icon: 'fe fe-move',
    //       key: 'Order Properties',
    //       url: '/properties/order',
    //     },
    //     {
    //       title: 'Bulk Update',
    //       icon: 'fe fe-moon',
    //       key: 'BulkUpdate',
    //       url: '/properties/bulk',
    //     },
    //     {
    //       title: 'LYP Submitted',
    //       icon: 'fe fe-users',
    //       key: 'Broker Properties',
    //       url: '/properties/broker',
    //     },
    //     {
    //       title: 'Buildings',
    //       icon: 'fe fe-underline',
    //       key: 'Buildings',
    //       url: '/properties/buildings',
    //     },
    //     {
    //       title: 'Website Sections',
    //       icon: 'fe fe-eye',
    //       key: 'WebsiteSections',
    //       url: '/properties/website-sections',
    //     },
    //   ],
    // },

    // MO

    // {
    //   category: CategoryTitle.Enquires,
    //   title: 'Enquiries',
    // },
    {
      title: 'Enquiries',
      key: 'Enquiries',
      icon: 'fe fe-phone',
      url: '/enquiries/MO',
      // roles: ['Admin', 'Developer', 'Manager', 'RM'], // set user roles with access to this route
      // // count: 2,
      // children: [
      //   {
      //     title: 'MO Enquiries',
      //     icon: 'fe fe-instagram',
      //     key: 'MO Enquiries',
      //     url: '/enquiries/MO',
      //   },
      // ],
    },
  ]
}
