import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { currentAccount, logout, signInWithGoogle } from 'services/firebase.auth.service'
import axios from 'axios'
import actions from './actions'

// export function* LOGIN({ payload }) {
//   const { email, password } = payload
//   yield put({
//     type: 'user/SET_STATE',
//     payload: {
//       loading: true,
//     },
//   })
//   const success = yield call(login, email, password)
//   yield put({
//     type: 'user/LOAD_CURRENT_ACCOUNT',
//   })
//   if (success) {
//     yield history.push('/')
//     notification.success({
//       message: 'Logged In',
//       description: 'You have successfully logged in to Propreturns Operations Dashboard!',
//     })
//   }
// }
const getAccessRoute = async Role => {
  try {
    const AccessibleRoutes = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=RBAC`,
    )
    let filteredAccessibleRoutes = null
    if (AccessibleRoutes) {
      AccessibleRoutes.data.body.forEach(element => {
        if (element.role === Role) {
          filteredAccessibleRoutes = element
        }
      })
    }
    return filteredAccessibleRoutes
  } catch (err) {
    console.log(err)
    return null
  }
}

export function* GOOGLE_SIGNIN() {
  const data = yield call(signInWithGoogle)

  if (data && data.ActiveEmployee) {
    const { Phone, Email, Name, Role } = data
    const AccessibleRoutes = yield call(getAccessRoute, Role)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: Name,
        email: Email,
        phone: Phone,
        role: Role,
        authorized: true,
        AccessibleRoutes: AccessibleRoutes,
      },
    })

    yield history.push('/')
    notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in to your dashboard!',
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)

  const demo = false
  if (response && response.ActiveEmployee) {
    const { Phone, Email, Name, Role } = response
    const AccessibleRoutes = yield call(getAccessRoute, Role)

    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: Name,
        email: Email,
        phone: Phone,
        role: Role,
        authorized: true,
        demo,
        AccessibleRoutes: AccessibleRoutes,
      },
    })
    yield put({
      type: 'menu/GET_DATA',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      phone: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GOOGLE_SIGNIN, GOOGLE_SIGNIN),
    //takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
