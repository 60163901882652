import { notification } from 'antd'
import axios from 'axios'
import { initializeApp } from '@firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from '@firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCETpzF3_aZ-VGRyg0GXRWTqLdamMLpfvo",
  authDomain: "wm-admin-81d0b.firebaseapp.com",
  projectId: "wm-admin-81d0b",
  storageBucket: "wm-admin-81d0b.appspot.com",
  messagingSenderId: "371537960571",
  appId: "1:371537960571:web:058f557646d6456ef48cde",
  measurementId: "G-9MW6C12KJ5"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(firebaseApp)
const provider = new GoogleAuthProvider()

export async function signInWithGoogle() {
  const team = await axios.get(
    `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=mo-team`,
  )

  const teamEmails = team.data.body.map(employee => {
    return employee.Email
  })

  return signInWithPopup(firebaseAuth, provider)
    .then(result => {
      const userCredential = result
      const { email } = userCredential.user

      if (!teamEmails.includes(email)) {
        notification.error({
          message: 'Invalid Credential',
          description: 'Your email is not registered with us. Please contact the PropReturns team for further assistance.',
        })
        return null
      }
      const memberData = team.data.body.find(member => member.Email === email)
      return memberData
    })
    .catch(err => {
      console.log(err)
    })
}
export async function logout() {
  return firebaseAuth.signOut().then(() => true)
}
export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser)
      }
      const unsubscribe = onAuthStateChanged(
        auth,
        user => {
          userLoaded = true
          unsubscribe()
          resolve(user)
        },
        reject,
      )
    })
  }
  const response = await getCurrentUser(firebaseAuth)
  if (response) {
    const { email } = response
    const team = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=mo-team`,
    )
    const memberData = team.data.body.find(member => member.Email === email)
    return memberData
  }
  return null
}

export default firebaseApp
