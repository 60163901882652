/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { takeEvery, put, all, call } from 'redux-saga/effects'
// import store from 'store'
// import qs from 'qs'
// eslint-disable-next-line no-unused-vars
import { store as reduxStore } from 'index'
import moment from 'moment'
import axios from 'axios'
import { message } from 'antd'
import actions from './actions'

const getEnquiries = async () => {
  try {
    const properties = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=properties-backup`,
    )

    //console.log('properties', properties)

    const users = await axios.get(
      `https://fs2r71hlhb.execute-api.ap-south-1.amazon.com/default/getDataFromMongo?collection=users`,
    )

    // Unique enquirers (users who have enquired on a minimum of 1 property)

    let UniqueEnquirers = 0
    if (users.data) {
      users.data.body.forEach((value, index) => {
        if (value.InterestedProperties && value.InterestedProperties.length !== 0) {
          UniqueEnquirers = UniqueEnquirers + 1
        }
      })
    }

    if (users.data.status === 200) {
      message.success('Enquiries loaded successfully.')
      let EnquiriesData = []

      // Mapping PIDs to property details

      const PID_TO_DETAILS_MAP = new Map()

      properties.data.body.forEach(property => {
        PID_TO_DETAILS_MAP.set(property.PID, property)
      })

      const team = await axios.get(
        `https://fs2r71hlhb.execute-api.ap-south-1.amazonaws.com/default/getDataFromMongo?collection=propreturns-team`,
      )

      const teamNumbers = team.data.body.map(employee => {
        return employee.Phone //mail
      })

      // Pushing enquiries
      console.log(users.data.body, 'check3')
      users.data.body.forEach(user => {
        if (!teamNumbers.includes(user.Phone))
          user.InterestedProperties?.forEach(InterestedProperty => {
            // Removing enquiries by the team
            EnquiriesData.push({
              PID: InterestedProperty.pid,
              City: InterestedProperty.pid.startsWith('FPID') // Mumbai for fractional
                ? 'Mumbai'
                : InterestedProperty.pid.startsWith('CFID') // Pune for construction finance
                ? 'Pune'
                : !PID_TO_DETAILS_MAP.has(InterestedProperty.pid) // Check if property data is available (lost some due to deletion and backup not configured properly)
                ? 'Property data not available'
                : PID_TO_DETAILS_MAP.get(InterestedProperty.pid).PropertyDetails.City,
              Name: user.Name,
              Phone: user.Phone,
              WebsiteSource: InterestedProperty.Source,
              createdAt: InterestedProperty.createdAt,
              UTM_Source: InterestedProperty.UTM_Data?.UTM_Source || null,
              UTM_Medium: InterestedProperty.UTM_Data?.UTM_Medium || null,
              UTM_Campaign: InterestedProperty.UTM_Data?.UTM_Campaign || null,
            })
          })
      })

      let Users = users.data.body

      return { EnquiriesData, UniqueEnquirers, Users }
    }
    if (users.data.status === 500) {
      message.error('There was an error processing your request. Try again or call Somil.')
      console.log(users.data.body)
      return null
    }
    return null
  } catch (err) {
    message.error('There was an error processing your request. Try again or call Somil.')
    console.log(err)
    return null
  }
}

const applyDateRange = async DateRange => {
  try {
    const RangedEnquiries = []
    const enquiries = reduxStore.getState().enquiries.OriginalEnquiries
    const uniqueEnquirers = reduxStore.getState().enquiries.OriginalUniqueEnquirers
    if (!DateRange) return { RangedEnquiries: enquiries, UniqueEnquirers: uniqueEnquirers }
    enquiries.forEach((value, index) => {
      if (
        moment(value.createdAt) > moment(DateRange[0]).startOf('day') &&
        moment(value.createdAt) < moment(DateRange[1]).startOf('day')
      ) {
        RangedEnquiries.push(value)
      }
    })

    // Unique enquirers

    const users = reduxStore.getState().enquiries.Users
    const USER_TO_PHONE_MAP = new Map()
    users.forEach(user => {
      USER_TO_PHONE_MAP.set(user.Phone, user)
    })

    let UniqueEnquirers = []
    RangedEnquiries.forEach((value, index) => {
      let DONT_PUSH_CUZ_OLD_USER = false
      USER_TO_PHONE_MAP.get(value.Phone).InterestedProperties?.forEach(interestedProperty => {
        if (moment(interestedProperty.createdAt) < moment(DateRange[0]).startOf('day'))
          DONT_PUSH_CUZ_OLD_USER = true
      })
      if (!UniqueEnquirers.includes(value.Phone) && !DONT_PUSH_CUZ_OLD_USER)
        UniqueEnquirers.push(value.Phone)
    })

    UniqueEnquirers = UniqueEnquirers.length
    message.success('Date range applied.')

    return { RangedEnquiries, UniqueEnquirers }
  } catch (err) {
    message.error(
      'There was an error processing your request. Check your connection or try again later.',
    )
    const temp = { ...reduxStore.getState().enquiries.OriginalEnquiries, EnquiriesLoading: false }
    console.log(err)
    return temp
  }
}

function* GET_ENQUIRIES() {
  yield put({
    type: 'enquiries/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: true,
    },
  })
  const enquiries = yield call(getEnquiries)
  yield put({
    type: 'enquiries/SET_STATE',
    payload: {
      Enquiries: enquiries.EnquiriesData,
      OriginalEnquiries: enquiries.EnquiriesData,
      UniqueEnquirers: enquiries.UniqueEnquirers,
      OriginalUniqueEnquirers: enquiries.UniqueEnquirers,
      Users: enquiries.Users,
    },
  })
  yield put({
    type: 'enquiries/ENQUIRIES_LOADING',
    payload: {
      EnquiriesLoading: false,
    },
  })
}

function* APPLY_DATE_RANGE({ payload: { DateRange } }) {
  yield put({
    type: 'enquiries/ENQUIRIES_LOADING',
    payload: {
      tableLoading: true,
    },
  })
  const { RangedEnquiries, UniqueEnquirers } = yield call(applyDateRange, DateRange)
  yield put({
    type: 'enquiries/SET_STATE',
    payload: { Enquiries: RangedEnquiries, UniqueEnquirers: UniqueEnquirers },
  })
  yield put({
    type: 'enquiries/ENQUIRIES_LOADING',
    payload: {
      tableLoading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ENQUIRIES, GET_ENQUIRIES),
    takeEvery(actions.APPLY_DATE_RANGE, APPLY_DATE_RANGE),
  ])
}
