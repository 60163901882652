import actions from './actions'

const initialState = {
  UsersData: null,
  OriginalUsersData: null,
  tableLoading: true,
}

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.TOGGLE_LOADING:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
